import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../utils/constants';
import { Link } from 'gatsby';
// import Img from 'gatsby-image';

import MainLayout from '../../layouts/mainlayout/MainLayout';
import PageHeader from '../../components/pageheader/PageHeader';

function Events() {
	const [events, setEvents] = useState([]);

	useEffect(() => {
		fetch(`${BASE_URL}/api/open/events`)
			.then(response => response.json())
			.then(data => {
				console.log("resp", data);
				setEvents(data.Data);
			})
	}, [])
	return (
		<MainLayout title="Events">
			<PageHeader title="Events" />
			<div className='announcements-list-layout'>
				<div className="row">
					<div className="row">
						{events.length > 0 ?
							events.map(event => {
								return (
									<Link
										to={`/event/${event.slug}`}
										className="col-12 col-sm-12 col-md-6 col-lg-4 mb-5 no-show-anchor"
									>
										<div className="announcement-item">
											<div className="announcement-image">
												{/* <Img fluid={event.frontmatter.thumb.childImageSharp.fluid} /> */}
												<img src={event.thumb} alt={event.name} />
											</div>
											<div className="announcement-body">
												<h5>{event.title}</h5>
												<div>{event.date}</div>
											</div>
										</div>
									</Link>
								)
							})
							:
							"No Events Found"
						}
					</div>
				</div>
			</div>
		</MainLayout>
	);
}
// export const query = graphql`
// query EventsList {
// 	allMarkdownRemark(
// 	  sort: {fields: frontmatter___date, order: DESC}
// 	  filter: {fileAbsolutePath: {regex: "/(events)\\/.*\\\\*.md/"}}
// 	) {
// 	  events: nodes {
// 		frontmatter {
// 		  date(formatString: "dddd, D MMMM yyyy")
// 		  slug
// 		  title
// 		  thumb {
// 			childImageSharp {
// 			  fluid {
// 				...GatsbyImageSharpFluid
// 			  }
// 			}
// 		  }
// 		}
// 	  }
// 	}
//   }
// `
export default Events;